import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductSmokingHero(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
    <Container className={classes.container}>
      <img
        src="/productCurvyLines.png"
        className={classes.curvyLines}
        alt="curvy lines"
      />
      <Typography variant="h4" marked="center" className={classes.title} component="h2">
          Resources
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={6} md={4}>
              <div className={classes.item}>
              <img
                  src="/game1_icon.png"
                  alt="graph"
                  className={classes.image}
                />
                <Typography variant="h5" align="center">
                BlockBreaker jar file <a href="BlockBreakerGame.jar"> Download</a>
                </Typography>
                </div> </Grid>
                <Grid item xs={6} md={4}>
              <div className={classes.item}>                
                <img
                  src="/imageoverlay.png"
                  alt="graph"
                  className={classes.image}
                />
                <Typography variant="h5" align="center">
                Image Overlay jar file <a href="ImageOverlay.jar"> Download</a>
                </Typography>
              </div>
          
            </Grid>
            
          </Grid>
        </div>
    </Container>
    </section>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSmokingHero);
