import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { CardActionArea } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: 100,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
});

function ProductCategories(props) {
  const { classes } = props;

  const solutions = 
      [{
        url:
          "/creativepay.png",
        title: "Crypto payment System",
        description: "CreativePay is an ethereum payment solution on telegram, which supports receive and send ERC20-USDT.",
        click_url: "http://t.me/dfpay2021_bot"
      },
      {
        url:
        "/nano_wallet.png",
        title: "Nano Wallet",
        description: "Nano is a fee-less, fast cryptocurrency. nano-cli-wallet is a headless nano wallet created using node.js. You can use it to create a wallet, send the nano to another address, and receive funds.",
        click_url: "https://github.com/creativeteck/nano-cli-wallet"
      },
      {
        url:
          "/aas.png",
        title: "Authentication as Service",
        description: "Authentication as Service to unify the authentication from different sources and apply consistent policies for remote or onsite users.",
        click_url: "http://www.simplecloud.info/"
      },{
        url:
          "/game1.png",
        title: "BlockBreaker",
        description: "BlockBreaker provides a simple twist to a classical and popular game. Free-to-play, it’s an easy and engaging way to pass your time. Go competitive or be casual, but remember to have fun! ",
        click_url: "https://youtu.be/PzYTl8W5x2I"
      },
      {
        url:
          "/imageoverlay.png",
        title: "Image Overlay",
        description: "Image Overlay is a great alternative to a second monitor. People turn to their second monitor for entertainment while grinding, a better minimap for resource collecting, or for referencing a damage rotation. With this application, you can emulate a second monitor with features such as: TopMost, ClickThrough, and Opacity.",
        click_url: "https://www.youtube.com/watch?v=oARZIXn4McU"
      }
    ];

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" align="center" component="h2">
        Our Solutions
      </Typography>
      <div>
        <Grid container spacing={4} style={{ padding: 24 }}>
          { 
            solutions.map(solution => (
                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        
                        <Card >
                        <CardActionArea href={solution.click_url}>
                          <CardMedia style={{ height: 0, paddingTop: '56.25%' }}
                            image={solution.url}
                            title={solution.title}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="headline" component="h2">
                              {solution.title}
                            </Typography>
                            <Typography component="p">
                              {solution.description}
                            </Typography>
                          </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
            ))}
        </Grid>
      </div>

    </Container>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategories);
